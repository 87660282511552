/* common */
.loading {
  font-size: 84px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  text-align: center;
  span {
    display: inline-block;
    margin: 0 -.05em;
  }
}

.container-animation-loading {
  color: #3f51b5;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* code #3 */
.loading03 {
    span {
      margin: 0 -.075em;
      animation: loading03 .7s infinite alternate;
      @for $i from 1 through 6 {
        &:nth-child(#{$i+1}) {
          animation-delay: #{$i*.1}s;
        }
      }
    }
  }
  @keyframes loading03 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(.8);
    }
  }
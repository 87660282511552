
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:wght@200&display=swap');

@import 'node_modules/react-modal-video/scss/modal-video.scss';

.clase{
    align-items: flex-end;
    justify-content: space-between;
    background-color: #c0ffb5;
    position: relative;
}
.session{
    background-color: "#D82B2B"!important;
}
.slick-arrow{
    width: auto!important;
    height: auto!important;
}
.slick-arrow::before{
    background-color: rgba(255, 255, 255, 0)!important;
    color: rgb(156, 156, 156)!important;
    font-size: 40px!important;
}
.slick-slide, .slick-active, .slick-current{
    width: auto!important;
}

/* elipsis multiline */
.truncate-multiline{
    display: 'block';
    display: -webkit-box;
    line-height: 1.5;
	overflow: hidden;
	position: relative;
	text-overflow: ellipsis;
    -webkit-line-clamp: 2;
  	-webkit-box-orient: vertical;
}

/* @media(max-width: 300){
    .slick-slide, .slick-active, .slick-current{
        width: 300px!important;
    }
} */

.politicas_privacidad a{
    font-size: 15px;
}
.politicas_privacidad p{
    font-size: 15px;
}
.politicas_privacidad h5{
    font-size: 20px;
}
.politicas_privacidad .politicas_link{
    font-size: 15px;
    color: blue;
}